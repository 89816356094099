import { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Pagination,
  Snackbar,
  Stack,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import MuiAlert from "@mui/material/Alert";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import CustomersApi from "api/customers";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import { useTranslation } from "react-i18next";
import { exportDataToCSV } from "utils/csvUtils";
import { storage } from "../../../firebase";
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

function UsersList() {
  const [customers, setCustomers] = useState([]);
  const [customersToExtract, setCustomersToExtract] = useState([]);
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [loadingExtract, setLoadingExtract] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for menu
  const [anchor, setAnchor] = useState(null); // Anchor for menu
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.business_role_id || null;
  const dashboard_access = role === "warehouse_owner" || role === "admin";

  const fetchCustomers = async (page) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await CustomersApi.GetCustomers(token, page);
      setCustomers(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(page);
  }, [page]);

  const fetchCustomersToExtract = async () => {
    try {
      setLoadingExtract(true);
      const token = localStorage.getItem("token");
      const response = await CustomersApi.GetCustomersoExtract(token);
      setCustomersToExtract(response.data);
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    } finally {
      setLoadingExtract(false);
    }
  };

  useEffect(() => {
    fetchCustomersToExtract();
  }, []);

  const formatDate = (dateString) =>
    dateString ? format(new Date(dateString), "yyyy-MM-dd HH:mm:ss") : "";

  const handlePageChange = (event, value) => setPage(value);

  const handleSortChange = (field) => {
    if (field === "username") {
      const newOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newOrder);
      sortCustomers("username", newOrder);
    } else if (field === "created_at") {
      const newOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newOrder);
      sortCustomers("created_at", newOrder);
    }
    setAnchor(null); // Close the menu after sorting
  };

  const sortCustomers = (field, order) => {
    const sortedCustomers = [...customers].sort((a, b) => {
      const valueA =
        field === "created_at" || field === "updated_at"
          ? new Date(a[field])
          : a[field]?.toLowerCase();
      const valueB =
        field === "created_at" || field === "updated_at"
          ? new Date(b[field])
          : b[field]?.toLowerCase();

      if (order === "asc") {
        return valueA > valueB ? 1 : -1;
      } else {
        return valueA < valueB ? 1 : -1;
      }
    });
    setCustomers(sortedCustomers);
  };

  const handleDataUpdate = useCallback(() => {
    fetchCustomers(page);
  }, [page]);

  const columns = [
    { Header: `${t("Client")}`, accessor: "username" },
    { Header: `${t("Prénom")}`, accessor: "firstname" },
    { Header: `${t("Nom de famille")}`, accessor: "lastname" },
    { Header: `${t("Téléphone")}`, accessor: "phone" },
    { Header: `${t("Code Client")}`, accessor: "client_code" },
    { Header: `${t("Ville")}`, accessor: "city" },
    { Header: `${t("Adresse")}`, accessor: "address_detail" },
    {
      Header: `${t("Créé le")}`,
      accessor: "created_at",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: `${t("Mis à jour le")}`,
      accessor: "updated_at",
      Cell: ({ value }) => formatDate(value),
    },
    { Header: `${t("Rôle d'affaires")}`, accessor: "business_role_id" },
    { Header: `${t("Mat Fiscale")}`, accessor: "mat_fiscale" },
    { Header: `${t("Type de Magasin")}`, accessor: "store_type" },
    { Header: `${t("Première Activité")}`, accessor: "first_activity" },
    { Header: `${t("Deuxième Activité")}`, accessor: "second_activity" },
    { Header: t("action"), accessor: "action" },
  ];

  const rows = customers.map((customer) => ({
    username: customer.username,
    firstname: customer.firstname,
    lastname: customer.lastname,
    phone: customer.phone,
    client_code: customer.client_code,
    city: customer.city,
    address_detail: customer.address_detail,
    created_at: customer.created_at,
    updated_at: customer.updated_at,
    business_role_id: customer?.business_role_id,
    mat_fiscale: customer.mat_fiscale,
    store_type: customer.store_type,
    first_activity: customer.first_activity,
    second_activity: customer.second_activity,
    action: <ActionCell customerId={customer.id} />,
  }));

  const columnsCSV = [
    { Header: "username", accessor: "username" },
    { Header: "client_code", accessor: "client_code" },
    { Header: "firstname", accessor: "firstname" },
    { Header: "lastname", accessor: "lastname" },
    { Header: "phone", accessor: "phone" },
    { Header: "city", accessor: "city" },
    { Header: "address_detail", accessor: "address_detail" },
    {
      Header: "created_at",
      accessor: "created_at",
    },
    {
      Header: "updated_at",
      accessor: "updated_at",
    },
    { Header: "business_role_id", accessor: "business_role_id" },
    { Header: "mat_fiscale", accessor: "mat_fiscale" },
    { Header: "store_type", accessor: "store_type" },
    { Header: "first_activity", accessor: "first_activity" },
    { Header: "second_activity", accessor: "second_activity" },
    { Header: "action", accessor: "action" },
  ];

  const rowsCSV = customersToExtract.map((customer) => ({
    username: customer.username,
    client_code: customer.client_code,
    firstname: customer.firstname,
    lastname: customer.lastname,
    phone: customer.phone,
    city: customer.city,
    address_detail: customer.address_detail,
    created_at: new Date(customer.created_at).toLocaleString(),
    updated_at: new Date(customer.updated_at).toLocaleString(),
    business_role_id: customer?.business_role_id,
    mat_fiscale: customer.mat_fiscale,
    store_type: customer.store_type,
    first_activity: customer.first_activity,
    second_activity: customer.second_activity,
    action: (
      <ActionCell customerId={customer.id} onDataUpdate={handleDataUpdate} />
    ),
  }));

  const handleExportToCSV = () => {
    const exportColumns = columnsCSV.filter(
      (column) => column.accessor !== "action"
    );
    const exportRows = rowsCSV.map((row) => {
      const { action, ...exportRow } = row;
      return exportRow;
    });
    exportDataToCSV(exportColumns, exportRows, "clients.csv");
  };

  const handleUploadButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleImportClientsClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setOpenModal(true);
  };

  const handleConfirmUpload = () => {
    setUploading(true);
    if (!file) {
      console.error("Aucun fichier sélectionné.");
      setUploading(false);
      return;
    }

    // Proceed with file upload logic here
    const fileId = Date.now();
    const fileName = `${file.name}_${fileId}`;

    const storageRef = ref(storage, "clients/" + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Téléchargement à " + progress + "% terminé");
      },
      (error) => {
        console.error("Échec du téléchargement:", error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          getMetadata(uploadTask.snapshot.ref)
            .then((metadata) => {
              const data = {
                file_link: metadata.name,
              };

              const token = localStorage.getItem("token");
              if (!token) {
                console.error("Aucun jeton trouvé dans le localStorage.");
                setUploading(false);
                return;
              }

              CustomersApi.UploadCustomers(data, token)
                .then((response) => {
                  setSnackbarMessage("Téléchargement réussi!");
                  setSnackbarOpen(true);
                })
                .catch((error) => {
                  console.error("Échec du téléchargement:", error);
                })
                .finally(() => {
                  setUploading(false);
                  setOpenModal(false);
                });
            })
            .catch((error) => {
              console.error(
                "Erreur lors de la récupération des métadonnées:",
                error
              );
              setUploading(false);
            });
        });
      }
    );
  };

  const handleCancelUpload = () => {
    setOpenModal(false);
    setFile(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          {!loading && !loadingExtract && (
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox>
                <Link to="/gestion-des-ventes/nouveau-client">
                  <SoftButton
                    variant="gradient"
                    color="info"
                    sx={{ marginRight: 2 }}
                  >
                    {t("Nouveau client")}
                  </SoftButton>
                </Link>
                <SoftButton
                  variant="outlined"
                  color="dark"
                  onClick={(e) => setAnchor(e.currentTarget)}
                >
                  {t("Trier par nom ou date")} (⬆⬇)
                </SoftButton>
                <Menu
                  anchorEl={anchor}
                  open={Boolean(anchor)}
                  onClose={() => setAnchor(null)}
                >
                  <MenuItem onClick={() => handleSortChange("username")}>
                    {t("Trier par Nom")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange("created_at")}>
                    {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"}
                    )
                  </MenuItem>
                </Menu>
              </SoftBox>
              {dashboard_access && (
                <SoftBox>
                  <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={handleUploadButtonClick}
                  >
                    {t("Importer")} / {t("Exporter")}
                  </SoftButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleImportClientsClick}>
                      {t("Importez la liste des clients")}
                    </MenuItem>
                    <MenuItem onClick={handleExportToCSV}>
                      {t("Exportez la liste des clients")}
                    </MenuItem>
                  </Menu>
                </SoftBox>
              )}
            </SoftBox>
          )}
          <Card>
            {loading || loadingExtract ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={false}
                  canSearch
                />
                <Stack spacing={2} mt={2}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                  />
                </Stack>
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ padding: "55px" }}
      >
        <DialogTitle>{t("Confirmer le Téléchargement du Fichier")}</DialogTitle>
        <DialogContent sx={{ padding: "25px" }}>
          <p>
            {t("Fichier Sélectionné")}:{" "}
            {file ? file.name : `${t("Aucun fichier sélectionné")}`}
          </p>
        </DialogContent>
        <DialogActions>
          <SoftButton
            variant="gradient"
            color="light"
            size="medium"
            onClick={handleCancelUpload}
          >
            {t("Annuler")}
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleConfirmUpload}
            disabled={uploading}
          >
            {uploading ? (
              <CircularProgress size={24} color="white" />
            ) : (
              `${t("Confirmer")}`
            )}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default UsersList;
