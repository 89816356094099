import ProductsList from "layouts/gestion-de-stock/liste-des-articles";
import Stock from "layouts/gestion-de-stock/situation-de-stock";
import DraftOrders from "layouts/gestion-des-commandes/commandes-encours";
import Notifications from "layouts/gestion-des-promotions/liste-des-notifications";
import Promotions from "layouts/gestion-des-promotions/liste-des-promotions";

import Shop from "examples/Icons/Shop";
import Basket from "examples/Icons/Basket";
import ConfirmedOrders from "layouts/gestion-des-commandes/commandes-confirmees";
import DeliveredOrders from "layouts/gestion-des-commandes/commandes-livrees";
import CancelledOrders from "layouts/gestion-des-commandes/commandes-annulees";
import OnWayOrders from "layouts/gestion-des-commandes/commandes-expediees";
import UsersList from "layouts/gestion-des-ventes/liste-des-clients";
import NewUser from "layouts/gestion-des-ventes/nouveau-client";
import NewStock from "layouts/gestion-de-stock/nouveau-stock";
import NewOrder from "layouts/gestion-des-commandes/ajouter-commande";
import BalanceClient from "layouts/gestion-des-ventes/balance-client";
import CompteClient from "layouts/gestion-des-ventes/compte-client";
import NewNotification from "layouts/gestion-des-promotions/nouvelle-notification";
import NewPromotion from "layouts/gestion-des-promotions/nouvelle-promotion";
import IncreaseStock from "layouts/gestion-de-stock/augmenter-le-stock";
import CreateInvoice from "layouts/gestion-des-ventes/etablir-une-facture";
import InvoiceStatus from "layouts/gestion-des-ventes/etat-de-facture";
import CustomerSupport from "examples/Icons/CustomerSupport";
import SpaceShip from "examples/Icons/SpaceShip";
import NewProduct from "layouts/gestion-de-stock/ajouter-articles";
import FinancialDocument from "examples/Icons/FinancialDocument";
import BankList from "layouts/gestion-financiere/liste-des-banques";
import CreateBank from "layouts/gestion-financiere/nouvelle-banque";
import CaisseList from "layouts/gestion-financiere/liste-des-caisses";
import CreateCaisse from "layouts/gestion-financiere/nouvelle-caisse";
import MesDemandesDeStock from "layouts/gestion-de-stock/mes-demandes-de-stock";
import SuppliersList from "layouts/gestion-de-stock/listes-des-fournisseurs";
import { useTranslation } from "react-i18next";
import NewManager from "layouts/gestion-des-ventes/managers/nouveau-manager";
import ManagersList from "layouts/gestion-des-ventes/managers/liste-des-managers";
import DeliveryNoteStatus from "layouts/gestion-des-ventes/etat-de-bon-de-livraison";
import ShoppingItems from "layouts/paniers-ouverts/paniers-en-cours";
import GenerateRoussoucreRetenue from "layouts/gestion-financiere/Generation-de-certificat-retenue-a-la-ressource";

const useNavRoutes = () => {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.business_role_id || null;
  const dashboard_access = role === "warehouse_owner" || role === "admin";

  const navRoutes = [
    {
      type: "collapse",
      name: t("Gestion des commandes"),
      key: "gestion-des-commandes",
      icon: <Basket size="12px" />,
      collapse: [
        {
          name: t("Ajouter commande"),
          key: "ajouter-commande",
          route: "/gestion-des-commandes/ajouter-commande",
          component: <NewOrder />,
          protected: true,
        },
        {
          name: t("Commandes encours"),
          key: "commandes-encours",
          route: "/gestion-des-commandes/commandes-encours",
          component: <DraftOrders />,
          protected: true,
        },
        {
          name: t("Commandes confirmées"),
          key: "commandes-confirmees",
          route: "/gestion-des-commandes/commandes-confirmees",
          component: <ConfirmedOrders />,
          protected: true,
        },
        {
          name: t("Commandes expediées"),
          key: "commandes-expediees",
          route: "/gestion-des-commandes/commandes-expediees",
          component: <OnWayOrders />,
          protected: true,
        },
        {
          name: t("Commandes livrées"),
          key: "commandes-livrees",
          route: "/gestion-des-commandes/commandes-livrees",
          component: <DeliveredOrders />,
          protected: true,
        },
        {
          name: t("Commandes annulées"),
          key: "commandes-annulees",
          route: "/gestion-des-commandes/commandes-annulees",
          component: <CancelledOrders />,
          protected: true,
        },
        !dashboard_access && {
          name: t("Panier d'achat en cours"),
          key: "paniers-en-cours",
          route: "/paniers-ouverts/paniers-en-cours",
          component: <ShoppingItems />,
          protected: true,
        },
      ].filter(Boolean),
    },

    {
      type: "collapse",
      name: t("Gestion des ventes"),
      key: "gestion-des-ventes",
      icon: <CustomerSupport size="12px" />,
      collapse: [
        {
          name: t("Ajouter client"),
          key: "nouveau-client",
          route: "/gestion-des-ventes/nouveau-client",
          component: <NewUser />,
          protected: true,
        },
        {
          name: t("Liste des clients"),
          key: "liste-des-clients",
          route: "/gestion-des-ventes/liste-des-clients",
          component: <UsersList />,
          protected: true,
        },
        dashboard_access && {
          name: t("ajouter manager"),
          key: "nouveau-manager",
          route: "/gestion-des-ventes/nouveau-manager",
          component: <NewManager />,
          protected: true,
        },
        {
          name: t("liste des managers"),
          key: "liste-des-managers",
          route: "/gestion-des-ventes/liste-des-managers",
          component: <ManagersList />,
          protected: true,
        },
        {
          name: t("Balance clients"),
          key: "balance-clients",
          route: "/gestion-des-ventes/balance-clients",
          component: <BalanceClient />,
          protected: true,
        },
        {
          name: t("Compte client"),
          key: "compte-client",
          route: "/gestion-des-ventes/compte-client",
          component: <CompteClient />,
          protected: true,
        },
        {
          name: t("Etablir une facture"),
          key: "etablir-une-facture",
          route: "/gestion-des-ventes/etablir-une-facture",
          component: <CreateInvoice />,
          protected: true,
        },
        {
          name: t("Etat de facture"),
          key: "etat-de-facture",
          route: "/gestion-des-ventes/etat-de-facture",
          component: <InvoiceStatus />,
          protected: true,
        },
        {
          name: t("Etat de bon de livraison"),
          key: "etat-de-bon-de-livraison",
          route: "/gestion-des-ventes/etat-de-bon-de-livraison",
          component: <DeliveryNoteStatus />,
          protected: true,
        },
      ].filter(Boolean),
    },

    {
      type: "collapse",
      name: t("Gestion de stock"),
      key: "gestion-de-stock",
      icon: <Shop size="12px" />,
      collapse: [
        {
          name: t("Ajouter articles"),
          key: "ajouter-articles",
          route: "/gestion-de-stock/ajouter-articles",
          component: <NewProduct />,
          protected: true,
        },
        {
          name: t("Liste des articles"),
          key: "liste-des-articles",
          route: "/gestion-de-stock/liste-des-articles",
          component: <ProductsList />,
          protected: true,
        },
        {
          name: t("Nouveau stock"),
          key: "nouveau-stock",
          route: "/gestion-de-stock/nouveau-stock",
          component: <NewStock />,
          protected: true,
        },
        {
          name: t("Augmenter le stock"),
          key: "augmenter-le-stock",
          route: "/gestion-de-stock/augmenter-le-stock",
          component: <IncreaseStock />,
          protected: true,
        },
        {
          name: t("Liste des fournisseurs"),
          key: "listes-des-fournisseurs",
          route: "/gestion-de-stock/listes-des-fournisseurs",
          component: <SuppliersList />,
          protected: true,
        },
        {
          name: t("Situation de stock"),
          key: "situation-de-stock",
          route: "/gestion-de-stock/situation-de-stock",
          component: <Stock />,
          protected: true,
        },
        {
          name: t("Mes demandes de stock"),
          key: "mes-demandes-de-stock",
          route: "/gestion-de-stock/mes-demandes-de-stock",
          component: <MesDemandesDeStock />,
          protected: true,
        },
      ].filter(Boolean),
    },
    {
      type: "collapse",
      name: t("Gestion des promotions"),
      key: "gestion-des-promotions",
      icon: <SpaceShip size="12px" />,
      collapse: [
        {
          name: t("Nouvelle promotion"),
          key: "nouvelle-promotion",
          route: "/gestion-des-promotions/nouvelle-promotion",
          component: <NewPromotion />,
          protected: true,
        },
        {
          name: t("Liste des promotions"),
          key: "liste-des-promotions",
          route: "/gestion-des-promotions/liste-des-promotions",
          component: <Promotions />,
          protected: true,
        },
        {
          name: t("Nouvelle notification"),
          key: "nouvelle-notification",
          route: "/gestion-des-promotions/nouvelle-notification",
          component: <NewNotification />,
          protected: true,
        },
        {
          name: t("Liste des notfications"),
          key: "liste-des-notifications",
          route: "/gestion-des-promotions/liste-des-notifications",
          component: <Notifications />,
          protected: true,
        },
      ],
    },
    {
      type: "collapse",
      name: t("Gestion financière"),
      key: "gestion-financiere",
      icon: <FinancialDocument size="12px" />,
      collapse: [
        dashboard_access && {
          name: t("Nouvelle caisse"),
          key: "nouvelle-caisse",
          route: "/gestion-financiere/nouvelle-caisse",
          component: <CreateCaisse />,
          protected: true,
        },
        {
          name: t("Liste des caisses"),
          key: "liste-des-caisses",
          route: "/gestion-financiere/liste-des-caisses",
          component: <CaisseList />,
          protected: true,
        },
        dashboard_access && {
          name: t("Nouvelle banque"),
          key: "nouvelle-banque",
          route: "/gestion-financiere/nouvelle-banque",
          component: <CreateBank />,
          protected: true,
        },
        {
          name: t("Liste des banques"),
          key: "liste-des-banques",
          route: "/gestion-financiere/liste-des-banques",
          component: <BankList />,
          protected: true,
        },
        {
          name: t("Certificat retenue a la ressources"),
          key: "generation-de-certificat-retenue-ressource",
          route:
            "/gestion-financiere/generation-de-certificat-retenue-ressource",
          component: <GenerateRoussoucreRetenue />,
          protected: true,
        },
      ].filter(Boolean),
    },
    // {
    //   type: "collapse",
    //   name: t("Gestion de paie"),
    //   key: "gestion-de-paie",
    //   icon: <FinancialDocument size="12px" />,
    // },
    // {
    //   type: "collapse",
    //   name: t("Gestion de comptabilité"),
    //   key: "gestion-de-comptabilité",
    //   icon: <FinancialDocument size="12px" />,
    // },
  ];

  // if (!dashboard_access) {
  //   return navRoutes.filter((route) => route.key !== "gestion-financiere");
  // } else {
    return navRoutes;
  // }
};

export default useNavRoutes;
