import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import { useTranslation } from "react-i18next";

function ProductInfo({ product }) {
  const { t } = useTranslation();

  // Function to strip HTML tags from a string
  const stripHtmlTags = (htmlString) => {
    const regex = /(<([^>]+)>)/gi;
    return htmlString?.replace(regex, "");
  };

  return (
    <SoftBox>
      <SoftBox mb={2}>
        <SoftTypography variant="h4" fontWeight="bold" color="textPrimary">
          {product?.product_name}
        </SoftTypography>
      </SoftBox>

      <SoftBox display="flex" gap={2} mb={3}>
        <SoftBadge
          variant="contained"
          color="info"
          badgeContent={product?.reference_code}
          container
          sx={{ fontWeight: "bold" }}
        />
        <SoftBadge
          variant="contained"
          color="success"
          badgeContent={product?.box ? t("Boîte") : t("Unité")}
          container
          sx={{ fontWeight: "bold" }}
        />
        <SoftBadge
          variant="contained"
          color="secondary"
          badgeContent={
            product?.is_taxed ? t("Soumis à la taxe") : t("Hors taxe")
          }
          container
          sx={{ fontWeight: "bold" }}
        />
      </SoftBox>

      <SoftBox mb={4}>
        <SoftTypography
          variant="h5"
          fontWeight="bold"
          color="textPrimary"
          mb={2}
        >
          {t("Détails du produit")}
        </SoftTypography>

        <SoftBox display="grid" gridTemplateColumns="1fr 2fr" gap={3}>
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Prix unitaire")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.unit_price} TND
          </SoftTypography>

          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Prix d'achat")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.buying_price} TND
          </SoftTypography>

          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Quantité minimale en stock")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.product_min_stock_quantity}
          </SoftTypography>

          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Catégorie")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.category_name}
          </SoftTypography>

          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Nombre maximum d'articles à commander")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.max_order}
          </SoftTypography>

          <SoftTypography
            variant="h6"
            fontWeight="medium"
            color="textSecondary"
          >
            {t("Fournisseur")}:
          </SoftTypography>
          <SoftTypography fontWeight="bold" color="textPrimary">
            {product?.suppliers_details
              .map((supplier) => supplier.name)
              .join(", ")}
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      <SoftBox>
        <SoftTypography
        
          component="div"
          color="textSecondary"
          dangerouslySetInnerHTML={{
            __html: stripHtmlTags(product?.description),
          }}
        />
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
