import { useEffect, useState, useMemo } from "react";
import { CircularProgress, Pagination, Stack } from "@mui/material";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BalanceApi from "api/balance";
import * as XLSX from "xlsx";
import SoftButton from "components/SoftButton";
import DataTable from "examples/Tables/DataTable";
import { useTranslation } from "react-i18next";

function BalanceClient() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.business_role_id || null;
  const dashboard_access = role === "warehouse_owner" || role === "admin";

  const fetchUserBalance = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.log("No token found in localStorage.");
      setLoading(false);
      return;
    }

    try {
      const response = await BalanceApi.GetClientBalance(token, page);
      if (response.data.results) {
        setUsers(response.data.results);
        const totalCount = response.data.count;
        setTotalPages(Math.ceil(totalCount / itemsPerPage));
      } else {
        console.log("No data found.");
      }
    } catch (error) {
      console.log(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserBalance();
    // eslint-disable-next-line
  }, [page]);

  const calculateTotals = (data) => {
    const totals = data.reduce(
      (acc, { user_details }) => {
        acc.totalSoldeDebit += parseFloat(user_details.total_orders_price) || 0;
        acc.totalSoldeCredit += parseFloat(user_details.total_paid_amount) || 0;
        acc.totalSoldeFinale += parseFloat(user_details.total_rest_to_pay) || 0;
        return acc;
      },
      { totalSoldeDebit: 0, totalSoldeCredit: 0, totalSoldeFinale: 0 }
    );

    return {
      totalSoldeDebit: `${totals.totalSoldeDebit.toFixed(3)} TND`,
      totalSoldeCredit: `${totals.totalSoldeCredit.toFixed(3)} TND`,
      totalSoldeFinale: `${totals.totalSoldeFinale.toFixed(3)} TND`,
    };
  };

  const rows = useMemo(() => {
    const totals = calculateTotals(users);

    return [
      ...users.map((user) => ({
        code: user.client_code || "",
        username: user.username || "",
        total_orders_price: user.user_details.total_orders_price
          ? `${parseFloat(user.user_details.total_orders_price).toFixed(3)} TND`
          : "",
        total_paid_amount: user.user_details.total_paid_amount
          ? `${parseFloat(user.user_details.total_paid_amount).toFixed(3)} TND`
          : "",
        total_rest_to_pay: user.user_details.total_rest_to_pay
          ? `${parseFloat(user.user_details.total_rest_to_pay).toFixed(3)} TND`
          : "",
      })),
      {
        code: "",
        username: <strong>{t("Total Mouvements")}</strong>,
        total_orders_price: <strong>{totals.totalSoldeDebit}</strong>,
        total_paid_amount: <strong>{totals.totalSoldeCredit}</strong>,
        total_rest_to_pay: "",
      },
      {
        code: "",
        username: "",
        total_orders_price: <strong>{t("Total")}</strong>,
        total_paid_amount: "",
        total_rest_to_pay: <strong>{totals.totalSoldeFinale}</strong>,
      },
    ];
  }, [users, t]);

  const columns = [
    { Header: `${t("Code client")}`, accessor: "code", width: "10%" },
    { Header: `${t("Client")}`, accessor: "username", width: "20%" },
    {
      Header: `${t("Solde Débit")}`,
      accessor: "total_orders_price",
      width: "20%",
    },
    {
      Header: `${t("Solde Crédit")}`,
      accessor: "total_paid_amount",
      width: "20%",
    },
    {
      Header: `${t("Solde Finale")}`,
      accessor: "total_rest_to_pay",
      width: "20%",
    },
  ];

  const exportToExcel = () => {
    const worksheetData = rows.map(
      ({
        code,
        username,
        total_orders_price,
        total_paid_amount,
        total_rest_to_pay,
      }) => [
        code,
        username,
        total_orders_price,
        total_paid_amount,
        total_rest_to_pay,
      ]
    );

    // Add header row
    const header = [
      `${t("Code")}`,
      `${t("Client")}`,
      `${t("Solde Débit")}`,
      `${t("Solde Crédit")}`,
      `${t("Solde Finale")}`,
    ];
    worksheetData.unshift(header);

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Define column widths (adjust as needed)
    worksheet["!cols"] = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    // Merge cells for "Total" row
    const totalRowIndex = worksheetData.length - 1;
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["", `${t("Total")}`, "", "", rows[rows.length - 1].total_rest_to_pay]],
      {
        origin: totalRowIndex,
      }
    );

    // Merge the cells from the second to the fourth for the total
    worksheet["!merges"] = [
      { s: { r: totalRowIndex, c: 1 }, e: { r: totalRowIndex, c: 3 } },
    ];

    // Add spaces to center the "Total" text
    const totalText = `${t("Total")}`;
    worksheet[`B${totalRowIndex + 1}`].v = totalText;

    // Create a workbook and export
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${t("Balance Client")}`);
    XLSX.writeFile(workbook, "BalanceClient.xlsx");
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Update the current page
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox
          sx={{
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          {dashboard_access && (
            <SoftButton
              variant="gradient"
              color="info"
              onClick={exportToExcel}
              sx={{
                marginBottom: 5,
              }}
            >
              {t("Exporter")}
            </SoftButton>
          )}
          {loading ? (
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={35}
            >
              <CircularProgress sx={{ color: "#1F51FF" }} />
            </SoftBox>
          ) : (
            <SoftBox sx={{ position: "relative" }}>
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                canSearch
              />
              <Stack
                spacing={2}
                sx={{
                  mt: 2,
                  position: "absolute",
                  bottom: 8,
                  left: 100,
                  transform: "translateX(-50%)",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="secondary"
                />
              </Stack>
            </SoftBox>
          )}
        </SoftBox>

        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default BalanceClient;
