import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CircularProgress,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Menu,
  MenuItem,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ProductCell from "./components/ProductCell";
import ActionCell from "./components/ActionCell";
import ProductsApi from "api/products";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getMetadata,
} from "firebase/storage";
import { storage } from "../../../firebase";
import { Pagination } from "@mui/material";
import { exportDataToCSV } from "utils/csvUtils";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import MuiAlert from "@mui/material/Alert";
import SoftInput from "components/SoftInput";
import { useTranslation } from "react-i18next";
import SupplierCell from "components/SoftTooltip";
import SupplierApi from "api/supplier";

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [productsToExtract, setProductsToExtract] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [showLoadingExtract, setShowLoadingExtract] = useState(false);
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const fileInputRef = useRef(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [exportPending, setExportPending] = useState(false);
  const [search, setSearch] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");

  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem("user"));
  const role = userData?.business_role_id || null;
  const dashboard_access = role === "warehouse_owner" || role === "admin";

  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      handleGetProducts(page, search, supplier);
    }, 500); // Adjust delay as needed (e.g., 500ms)

    return () => clearTimeout(searchTimeoutRef.current);
  }, [search, page, supplier]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleGetProductsToExtract();
        setDataFetched(true);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    const token = localStorage.getItem("token");
    try {
      const supplierData = await SupplierApi.GetSuppliersList(token);
      setSuppliers(supplierData.data.results);
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
    }
  };

  const handleGetProducts = async (page, search, supplier) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans le localStorage.");
      return;
    }

    setLoading(true);
    if (search) setLoadingSearch(true);

    try {
      const apiCall =
        supplier !== ""
          ? ProductsApi.GetProducts(token, page, supplier)
          : ProductsApi.SearchProductByName(token, page, search);

      const response = await apiCall;

      if (response?.data) {
        const updatedProducts = response.data.results || [];
        setProducts(updatedProducts);

        if (!updatedProducts.length) {
          console.warn("Aucun produit trouvé pour les critères sélectionnés.");
        }

        setTotalPages(Math.ceil(response.data.count / 10) || 0);
      } else {
        console.warn(
          "Aucune donnée trouvée. Message de l'API:",
          response?.data?.msg || "Non spécifié."
        );
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données:",
        error.response?.data?.msg ||
          error.message ||
          "Une erreur s'est produite."
      );
    } finally {
      setLoading(false);
      if (search) setLoadingSearch(false);
    }
  };

  const handleGetProductsToExtract = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Aucun jeton trouvé dans le localStorage.");
      return Promise.reject("Aucun jeton trouvé dans le localStorage.");
    }
    setLoadingExtract(true);
    return ProductsApi.GetProductsToExtract(token)
      .then((response) => {
        if (response.data) {
          setProductsToExtract(response.data);
          return response.data;
        } else {
          console.warn("Aucune donnée trouvée:", response.data.msg);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur de réponse des données:",
          error.response?.data?.msg || "Une erreur s'est produite."
        );
      })
      .finally(() => {
        setLoadingExtract(false);
      });
  };

  const handleDataUpdate = () => {
    handleGetProducts(page, search);
  };

  const handleSortChange = (field) => {
    let newSortOrder;

    if (field === "product_name") {
      newSortOrder = nameSortOrder === "asc" ? "desc" : "asc";
      setNameSortOrder(newSortOrder);
      sortProducts(field, newSortOrder);
    } else if (field === "created_at") {
      newSortOrder = dateSortOrder === "asc" ? "desc" : "asc";
      setDateSortOrder(newSortOrder);
      sortProducts(field, newSortOrder);
    }
  };

  const sortProducts = (field, order) => {
    const sortedProducts = [...products].sort((a, b) => {
      const valueA =
        field === "created_at" ? new Date(a[field]) : a[field]?.toLowerCase();
      const valueB =
        field === "created_at" ? new Date(b[field]) : b[field]?.toLowerCase();

      if (order === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setProducts(sortedProducts); // Update the products list with sorted data
  };

  const rows = React.useMemo(
    () =>
      products.map((product) => ({
        product_name: product.product_name,
        unit_price: `${product.unit_price} TND`,
        category_name: product.category_name,
        supplier:
          product.suppliers_details && product.suppliers_details.length > 0
            ? product.suppliers_details.map((supplier) => supplier.name)
            : [t("Fournisseur non spécifié")],
        max_order: product.max_order,
        total_price: `${product.total_price} TND`,
        action: product.id,
      })),
    [products, t]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: `${t("Nom du produit")}`,
        accessor: "product_name",
        sortable: true,
      },
      { Header: `${t("Prix unitaire")}`, accessor: "unit_price" },
      { Header: `${t("Catégorie")}`, accessor: "category_name" },
      {
        Header: `${t("Fournisseur")}`,
        accessor: "supplier",
        Cell: ({ value }) => <SupplierCell value={value} />,
      },
      { Header: `${t("Commande Max")}`, accessor: "max_order" },
      { Header: `${t("Prix Total")}`, accessor: "total_price" },
      {
        Header: t("action"),
        accessor: "action",
        Cell: ({ value }) => (
          <ActionCell productId={value} onDataUpdate={handleDataUpdate} />
        ),
      },
    ],
    // eslint-disable-next-line
    [t]
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columnsCSV = React.useMemo(
    () => [
      { Header: "product_name", accessor: "product_name" },
      { Header: "reference_code", accessor: "reference_code" },
      { Header: "unit_price", accessor: "unit_price" },
      { Header: "category_name", accessor: "category_name" },
      // {
      //   Header: "supplier",
      //   accessor: "supplier",
      //   Cell: ({ value }) => <SupplierCell value={value} />,
      // },
      { Header: "box", accessor: "box" },
      { Header: "box_items_quantity", accessor: "box_items_quantity" },
      { Header: "total_price", accessor: "total_price" },
      { Header: "buying_price", accessor: "buying_price" },
      { Header: "max_order", accessor: "max_order" },
      { Header: "status", accessor: "status" },
      { Header: "description", accessor: "description" },
      {
        Header: "product_image",
        accessor: "product_image",
        Cell: ({ value }) => <ProductCell image={value} />,
      },
    ],
    // eslint-disable-next-line
    [t]
  );

  const rowsCSV = React.useMemo(
    () =>
      productsToExtract.map((product) => ({
        id: product.id,
        product_name: product.product_name,
        reference_code: product.reference_code,
        unit_price: product.unit_price,
        category_name: product.category_name,
        // supplier:
        //   suppliers.find((supplier) => supplier.id === product.supplier_id)
        //     ?.name || t("Fournisseur non spécifié"),
        box: product.box,
        box_items_quantity: product.box_items_quantity,
        total_price: product.total_price,
        buying_price: product.buying_price,
        max_order: product.max_order,
        status: product.status,
        description: product.description,
        product_image: product.product_image,
      })),
    // eslint-disable-next-line
    [productsToExtract, t]
  );

  const handleExportToCSV = useCallback(() => {
    const exportColumns = columnsCSV.filter(
      (column) => column.accessor !== "action"
    );
    const exportRows = rowsCSV.map((row) => {
      const { action, ...exportRow } = row;
      return exportRow;
    });
    exportDataToCSV(exportColumns, exportRows, "produits.csv");
  }, [columnsCSV, rowsCSV]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setOpenModal(true);
  };

  const handleUploadButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleExportToCSVClick = useCallback(() => {
    if (!dataFetched) {
      setExportPending(true);
    } else {
      handleExportToCSV();
      handleMenuClose();
    }
  }, [dataFetched, handleExportToCSV, handleMenuClose]);

  useEffect(() => {
    if (dataFetched && exportPending) {
      setShowLoadingExtract(false);
      setExportPending(false);
      handleExportToCSV();
      handleMenuClose();
    }
  }, [dataFetched, exportPending, handleExportToCSV, handleMenuClose]);

  const handleImportProductsClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleConfirmUpload = () => {
    setUploading(true);
    if (!file) {
      console.error("Aucun fichier sélectionné.");
      setUploading(false);
      return;
    }

    // Proceed with file upload logic here
    const fileId = Date.now();
    const fileName = `${file.name}_${fileId}`;

    const storageRef = ref(storage, "products/" + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Téléchargement à " + progress + "% terminé");
      },
      (error) => {
        console.error("Échec du téléchargement:", error);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          getMetadata(uploadTask.snapshot.ref)
            .then((metadata) => {
              const data = {
                file_link: metadata.name,
              };

              const token = localStorage.getItem("token");
              if (!token) {
                console.error("Aucun jeton trouvé dans le localStorage.");
                setUploading(false);
                return;
              }

              Promise.race([
                ProductsApi.UploadProducts(data, token),
                new Promise((_, reject) =>
                  setTimeout(
                    () =>
                      reject(new Error("Délai dépassé, veuillez réessayer.")),
                    300000
                  )
                ),
              ])
                .then((response) => {
                  setSnackbarMessage("Téléchargement réussi!");
                  setSnackbarOpen(true);
                })
                .catch((error) => {
                  console.error("Échec du téléchargement:", error);
                  setSnackbarMessage(
                    error.message || "Échec du téléchargement."
                  );
                  setSnackbarOpen(true);
                })
                .finally(() => {
                  setUploading(false);
                  setOpenModal(false);
                });
            })
            .catch((error) => {
              console.error(
                "Erreur lors de la récupération des métadonnées:",
                error
              );
              setUploading(false);
            });
        });
      }
    );
  };

  const handleCancelUpload = () => {
    setOpenModal(false);
    setFile(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSupplierChange = (selectedSupplier) => {
    setSupplier(selectedSupplier.label); // Update the supplier state
    setPage(1); // Reset to the first page
    handleGetProducts(1, search, selectedSupplier.label); // Fetch products for the selected supplier
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading && (
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <SoftBox>
            <Link to="/gestion-de-stock/ajouter-articles">
              <SoftButton
                variant="gradient"
                color="info"
                sx={{ marginRight: 2 }}
              >
                + {t("Nouveau Produit")}
              </SoftButton>
            </Link>
            <SoftButton
              variant="outlined"
              color="dark"
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              {t("Trier par nom ou date")} (⬆⬇)
            </SoftButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => handleSortChange("product_name")}>
                {t("Trier par Nom")} ({nameSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
              <MenuItem onClick={() => handleSortChange("created_at")}>
                {t("Trier par Date")} ({dateSortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
            </Menu>
          </SoftBox>
          {dashboard_access && (
            <React.Fragment>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <SoftButton
                variant="gradient"
                color="info"
                onClick={handleUploadButtonClick}
              >
                {t("Importer")} / {t("Exporter")}
              </SoftButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleImportProductsClick}>
                  {t("Importez les Produits")}
                </MenuItem>
                {!showLoadingExtract ? (
                  <MenuItem
                    onClick={() => {
                      setShowLoadingExtract(loadingExtract);
                      handleExportToCSVClick();
                    }}
                  >
                    {t("Exportez les Produits")}
                  </MenuItem>
                ) : (
                  <MenuItem>
                    {t("Exportation en cours")}
                    <CircularProgress
                      size={15}
                      sx={{ color: "#1F51FF", marginLeft: "10px" }}
                    />
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          )}
        </Stack>
      )}
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="80vh"
      >
        <SoftBox my={3}>
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <>
                <SoftBox
                  position="relative"
                  width="100%"
                  ml="auto"
                  mb={-5}
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <SoftBox>
                    <SoftButton
                      variant="outlined"
                      color="dark"
                      onClick={(e) => setAnchor(e.currentTarget)}
                    >
                      {t("Sélectionnez un fournisseur")}
                    </SoftButton>
                    <Menu
                      anchorEl={anchor}
                      open={Boolean(anchor)}
                      onClose={() => setAnchor(null)}
                    >
                      {suppliers.map((supplier) => (
                        <MenuItem
                          key={supplier.id}
                          onClick={() => {
                            handleSupplierChange({
                              value: supplier.id,
                              label: supplier.name,
                            });
                            setAnchor(null); // Close the menu
                          }}
                        >
                          {supplier.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </SoftBox>

                  {/* <SoftBox>
                    <SoftTypography variant="caption" fontWeight="bold">
                      {t("Fournisseur")}
                    </SoftTypography>
                    <SoftSelect
                      options={suppliers.map((s) => ({
                        value: s.id,
                        label: s.name,
                      }))}
                      value={supplier.length > 0 ? supplier[0].id : ""}
                      onChange={(event) => handleSupplierChange(event)}
                      placeholder={t("Sélectionnez un fournisseur")}
                    />
                  </SoftBox> */}
                  <SoftBox mb={3} width="15em">
                    <SoftInput
                      placeholder={`${t("Rechercher")}...`}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{
                        width: "100%",
                        paddingRight: loadingSearch ? "40px" : "10px",
                      }}
                    />
                  </SoftBox>

                  {loadingSearch && (
                    <CircularProgress
                      sx={{
                        color: "grey",
                        position: "absolute",
                        right: "25px",
                        top: "35%",
                        transform: "translateY(-50%)",
                      }}
                      size={18}
                    />
                  )}
                </SoftBox>
                <DataTable
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [5, 10, 15, 20, 25],
                  }}
                  // canSearch
                  showTotalEntries
                  pagination={{ variant: "gradient", color: "info" }}
                  isSorted
                  noEndBorder
                  table={{ columns, rows }}
                />
                {totalPages > 1 && (
                  <Stack
                    spacing={2}
                    sx={{
                      mt: 2,
                      position: "absolute",
                      bottom: 7,
                      left: 200,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                    />
                  </Stack>
                )}
              </>
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>

      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ padding: "55px" }}
      >
        <DialogTitle>{t("Confirmer le Téléchargement du Fichier")}</DialogTitle>
        <DialogContent sx={{ padding: "25px" }}>
          <p>
            {t("Fichier Sélectionné")}:{" "}
            {file ? file.name : `${t("Aucun fichier sélectionné")}`}
          </p>
        </DialogContent>
        <DialogActions>
          <SoftButton
            variant="gradient"
            color="light"
            size="medium"
            onClick={handleCancelUpload}
          >
            {t("Annuler")}
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={handleConfirmUpload}
            disabled={uploading}
          >
            {uploading ? (
              <CircularProgress size={24} color="white" />
            ) : (
              `${t("Confirmer")}`
            )}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default ProductsList;
